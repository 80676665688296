var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content mb-5"},[_c('page-header',{attrs:{"title":_vm.$t('common.reviews_employees'),"sub-title":_vm.$t('common.reviews_employees_subtitle')}}),(_vm.getAllReviews)?_c('section',{staticClass:"\n      wameed-dashboard-page-content_body\n      d-flex\n      flex-column\n      justify-content-between\n    "},[_c('div',{staticClass:"d-flex flex-wrap flex-1 px-2"},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('div',{staticClass:"w-table d-flex flex-column"},[(_vm.getAllReviews)?_c('w-tables',{attrs:{"per-page":_vm.filterData.per_page,"items":_vm.getAllReviews,"fields":_vm.fields,"custimized-items":[
              { name: 'name' },
              { name: 'stars' },
              { name: 'created_at' } ]},scopedSlots:_vm._u([{key:"name",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-center align-items-center"},[_c('b-avatar',{attrs:{"variant":"","size":"50"}},[(data.item.photo)?_c('img',{attrs:{"src":data.item.photo.full_path + data.item.photo.file_name}}):_c('default-user-image-icon')],1),_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(data.item.name))])],1)]}},{key:"stars",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_c('b-form-rating',{staticClass:"cusstom_rating_table",attrs:{"dir":"rtl","size":"sm","readonly":""},model:{value:(data.item.stars),callback:function ($$v) {_vm.$set(data.item, "stars", $$v)},expression:"data.item.stars"}})],1)])]}},{key:"created_at",fn:function(ref){
            var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text-font-main mx-3"},[_vm._v(_vm._s(_vm.formatedDate(data.item.created_at)))])])]}}],null,false,1944007907)}):_vm._e(),(_vm.getAllReviews && _vm.getAllReviews.length < 1)?_c('wameed-no-data',{attrs:{"icon":"nodata-icon","title":_vm.$t('offers_page.no_data'),"sub-title":_vm.$t('offers_page.no_data_text')}}):_vm._e()],1)]),(_vm.getAllReviews && _vm.getAllReviews.length > 0)?_c('b-col',{staticClass:"px-0",attrs:{"lg":"12","md":"12"}},[_c('wameed-pagination',{attrs:{"page":_vm.filterData.page,"total-items":_vm.getTotalReviews.totalItems,"per_page":_vm.filterData.per_page},on:{"changePage":_vm.changePage,"changeCurrentPage":_vm.changeCurrentPage},model:{value:(_vm.filterData.page),callback:function ($$v) {_vm.$set(_vm.filterData, "page", $$v)},expression:"filterData.page"}})],1):_vm._e()],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }